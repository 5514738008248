import styled from 'styled-components'
import { Text, Flex } from '@pancakeswap/uikit'

export const ClickableColumnHeader = styled(Text)`
  cursor: pointer;
`

export const TableWrapper = styled(Flex)`
  width: 100%;
  padding-top: 1.6rem;
  flex-direction: column;
  gap: 1.6rem;
  background-color: ${({ theme }) => theme.card.background};
  border-radius: ${({ theme }) => theme.radii.card};
  border: 0.1rem solid ${({ theme }) => theme.colors.cardBorder};
`

export const PageButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.2em;
  margin-bottom: 1.2em;
`

export const Arrow = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  padding: 0 2rem;
  :hover {
    cursor: pointer;
  }
`

export const Break = styled.div`
  height: 0.1rem;
  background-color: ${({ theme }) => theme.colors.cardBorder};
  width: 100%;
`
