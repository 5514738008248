// TODO PCS refactor ternaries
/* eslint-disable no-nested-ternary */
import { useCallback, useState, useMemo, useEffect } from 'react'
import styled from 'styled-components'
import { formatDistanceToNowStrict } from 'date-fns'
import { Text, Flex, Box, Radio, Skeleton, LinkExternal, ArrowForwardIcon, ArrowBackIcon } from '@pancakeswap/uikit'
import { formatAmount } from 'utils/formatInfoNumbers'
import { getBlockExplorerLink } from 'utils'
import truncateHash from 'utils/truncateHash'
import { Transaction, TransactionType } from 'state/info/types'
import { ITEMS_PER_INFO_TABLE_PAGE } from 'config/constants/info'
import { useTranslation } from 'contexts/Localization'
import { PageButtons, Arrow } from './shared'
import styles from './Tables.module.scss'

const Wrapper = styled.div`
  width: 100%;
`

const ResponsiveGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: center;
  grid-template-columns: 2fr 0.8fr repeat(4, 1fr);
  padding: 0 2.4rem;
  @media screen and (max-width: 94rem) {
    grid-template-columns: 2fr repeat(4, 1fr);
    & > *:nth-child(5) {
      display: none;
    }
  }
  @media screen and (max-width: 80rem) {
    grid-template-columns: 2fr repeat(2, 1fr);
    & > *:nth-child(5) {
      display: none;
    }
    & > *:nth-child(3) {
      display: none;
    }
    & > *:nth-child(4) {
      display: none;
    }
  }
  @media screen and (max-width: 50rem) {
    grid-template-columns: 2fr 1fr;
    & > *:nth-child(5) {
      display: none;
    }
    & > *:nth-child(3) {
      display: none;
    }
    & > *:nth-child(4) {
      display: none;
    }
    & > *:nth-child(2) {
      display: none;
    }
  }
`

const RadioGroup = styled(Flex)`
  align-items: center;
  margin-right: 1.6rem;
  margin-top: 0.8rem;
  cursor: pointer;
`

const SORT_FIELD = {
  amountUSD: 'amountUSD',
  timestamp: 'timestamp',
  sender: 'sender',
  amountToken0: 'amountToken0',
  amountToken1: 'amountToken1',
}

const TableLoader: React.FC = () => {
  const loadingRow = (
    <ResponsiveGrid>
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </ResponsiveGrid>
  )
  return (
    <>
      {loadingRow}
      {loadingRow}
      {loadingRow}
    </>
  )
}

const DataRow: React.FC<{ transaction: Transaction }> = ({ transaction }) => {
  const { t } = useTranslation()
  const abs0 = Math.abs(transaction.amountToken0)
  const abs1 = Math.abs(transaction.amountToken1)
  const outputTokenSymbol = transaction.amountToken0 < 0 ? transaction.token0Symbol : transaction.token1Symbol
  const inputTokenSymbol = transaction.amountToken1 < 0 ? transaction.token0Symbol : transaction.token1Symbol

  return (
    <div className={styles.transactions_tab}>
      <div className={styles.text}>
        <LinkExternal href={getBlockExplorerLink(transaction.hash, 'transaction')}>
          <h6>
            {transaction.type === TransactionType.MINT
              ? t('Add %token0% and %token1%', { token0: transaction.token0Symbol, token1: transaction.token1Symbol })
              : transaction.type === TransactionType.SWAP
                ? t('Swap %token0% for %token1%', { token0: inputTokenSymbol, token1: outputTokenSymbol })
                : t('Remove %token0% and %token1%', { token0: transaction.token0Symbol, token1: transaction.token1Symbol })
            }
          </h6>
        </LinkExternal>
      </div>

      <div className={styles.text}>
        <h6>${formatAmount(transaction.amountUSD)}</h6>
      </div>
      <div className={styles.text}>
        <h6>{`${formatAmount(abs0)} ${transaction.token0Symbol}`}</h6>
      </div>
      <div className={styles.text}>
        <h6>{`${formatAmount(abs1)} ${transaction.token1Symbol}`}</h6>
      </div>
      <div className={styles.text}>
        <LinkExternal className={styles.gradText} href={getBlockExplorerLink(transaction.sender, 'address')}>
          {truncateHash(transaction.sender)}
        </LinkExternal>
      </div>
      <div className={styles.text}>
        <h6>{formatDistanceToNowStrict(parseInt(transaction.timestamp, 10) * 1000)}</h6>
      </div>
    </div>
  )
}

const TransactionTable: React.FC<{
  transactions: Transaction[]
}> = ({ transactions }) => {
  const [sortField, setSortField] = useState(SORT_FIELD.timestamp)
  const [sortDirection, setSortDirection] = useState<boolean>(true)
  const { t } = useTranslation()
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  const [txFilter, setTxFilter] = useState<TransactionType | undefined>(undefined)

  const sortedTransactions = useMemo(() => {
    const toBeAbsList = [SORT_FIELD.amountToken0, SORT_FIELD.amountToken1]
    return transactions
      ? transactions
        .slice()
        .sort((a, b) => {
          if (a && b) {
            const firstField = a[sortField as keyof Transaction]
            const secondField = b[sortField as keyof Transaction]
            const [first, second] = toBeAbsList.includes(sortField)
              ? [Math.abs(firstField as number), Math.abs(secondField as number)]
              : [firstField, secondField]
            return first > second ? (sortDirection ? -1 : 1) * 1 : (sortDirection ? -1 : 1) * -1
          }
          return -1
        })
        .filter((x) => {
          return txFilter === undefined || x.type === txFilter
        })
        .slice(ITEMS_PER_INFO_TABLE_PAGE * (page - 1), page * ITEMS_PER_INFO_TABLE_PAGE)
      : []
  }, [transactions, page, sortField, sortDirection, txFilter])

  // Update maxPage based on amount of items & applied filtering
  useEffect(() => {
    if (transactions) {
      const filteredTransactions = transactions.filter((tx) => {
        return txFilter === undefined || tx.type === txFilter
      })
      if (filteredTransactions.length % ITEMS_PER_INFO_TABLE_PAGE === 0) {
        setMaxPage(Math.floor(filteredTransactions.length / ITEMS_PER_INFO_TABLE_PAGE))
      } else {
        setMaxPage(Math.floor(filteredTransactions.length / ITEMS_PER_INFO_TABLE_PAGE) + 1)
      }
    }
  }, [transactions, txFilter])

  const handleFilter = useCallback(
    (newFilter: TransactionType) => {
      if (newFilter !== txFilter) {
        setTxFilter(newFilter)
        setPage(1)
      }
    },
    [txFilter],
  )

  const handleSort = useCallback(
    (newField: string) => {
      setSortField(newField)
      setSortDirection(sortField !== newField ? true : !sortDirection)
    },
    [sortDirection, sortField],
  )

  const arrow = useCallback(
    (field: string) => {
      const directionArrow = !sortDirection ? '↑' : '↓'
      return sortField === field ? directionArrow : ''
    },
    [sortDirection, sortField],
  )

  return (
    <Wrapper>
      <Flex mb="1.6rem">
        <Flex flexDirection={['column', 'row']}>
          <RadioGroup onClick={() => handleFilter(undefined)}>
            <Radio className={styles.radioBtn} onChange={() => null} scale="sm" checked={txFilter === undefined} />
            <Text ml="0.8rem">{t('All')}</Text>
          </RadioGroup>

          <RadioGroup onClick={() => handleFilter(TransactionType.SWAP)}>
            <Radio className={styles.radioBtn}  onChange={() => null} scale="sm" checked={txFilter === TransactionType.SWAP} />
            <Text ml="0.8rem">{t('Swaps')}</Text>
          </RadioGroup>
        </Flex>

        <Flex flexDirection={['column', 'row']}>
          <RadioGroup onClick={() => handleFilter(TransactionType.MINT)}>
            <Radio className={styles.radioBtn}  onChange={() => null} scale="sm" checked={txFilter === TransactionType.MINT} />
            <Text ml="0.8rem">{t('Adds')}</Text>
          </RadioGroup>

          <RadioGroup onClick={() => handleFilter(TransactionType.BURN)}>
            <Radio className={styles.radioBtn}  onChange={() => null} scale="sm" checked={txFilter === TransactionType.BURN} />
            <Text ml="0.8rem">{t('Removes')}</Text>
          </RadioGroup>
        </Flex>
      </Flex>



      <div className={styles.pools_wrapper}>
        <div className={styles.pools_container}>
          <div className={styles.pools_row}>
            <div className={styles.transactions_tab}>
              <div className={styles.text}>
                <h6>{t('Action')}</h6>
              </div>
              <div className={styles.text}>
                <h6
                  className={styles.clickableText}
                  onClick={() => handleSort(SORT_FIELD.amountUSD)}
                >
                  {t('Total Value')} {arrow(SORT_FIELD.amountUSD)}
                </h6>
              </div>
              <div className={styles.text}>
                <h6
                  className={styles.clickableText}
                  onClick={() => handleSort(SORT_FIELD.amountToken0)}
                >
                  {t('Token Amount')} {arrow(SORT_FIELD.amountToken0)}
                </h6>
              </div>
              <div className={styles.text}>
                <h6
                  className={styles.clickableText}
                  onClick={() => handleSort(SORT_FIELD.amountToken1)}
                >
                  {t('Token Amount')} {arrow(SORT_FIELD.amountToken1)}
                </h6>
              </div>
              <div className={styles.text}>
                <h6
                  className={styles.clickableText}
                  onClick={() => handleSort(SORT_FIELD.sender)}
                >
                  {t('Sender')} {arrow(SORT_FIELD.sender)}
                </h6>
              </div>
              <div className={styles.text}>
                <h6
                  className={styles.clickableText}
                  onClick={() => handleSort(SORT_FIELD.timestamp)}
                >
                  {t('Time')} {arrow(SORT_FIELD.timestamp)}
                </h6>
              </div>
            </div>
          </div>

          {transactions ? (
            <>
              {sortedTransactions.map((transaction, i) => {
                if (transaction) {
                  return (
                    <div className={styles.pools_row} key={i}>
                      <DataRow transaction={transaction} />
                    </div>
                  )
                }
                return null
              })}
              {sortedTransactions.length === 0 ? (
                <Flex justifyContent="center">
                  <Text>{t('No Transactions')}</Text>
                </Flex>
              ) : undefined}
              <PageButtons>
                <Arrow
                  onClick={() => {
                    setPage(page === 1 ? page : page - 1)
                  }}
                >
                  <ArrowBackIcon color={page === 1 ? 'textDisabled' : 'primary'} />
                </Arrow>

                <Text>{t('Page %page% of %maxPage%', { page, maxPage })}</Text>
                <Arrow
                  onClick={() => {
                    setPage(page === maxPage ? page : page + 1)
                  }}
                >
                  <ArrowForwardIcon color={page === maxPage ? 'textDisabled' : 'primary'} />
                </Arrow>
              </PageButtons>
            </>
          ) : (
            <>
              <TableLoader />
              <Box />
            </>
          )}
        </div>
      </div>
    </Wrapper>
  )
}

export default TransactionTable
